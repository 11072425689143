@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Comforter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bonheur+Royale&family=Island+Moments&display=swap');

*{
  font-family: 'Poppins', sans-serif;
}
h1,h2, .my-logo{
  font-family: 'Josefin Sans', sans-serif;
}

.verse-styles{
  /* font-family: 'Comforter', cursive;  */
  /* font-family: 'Island Moments', cursive; */
  font-family: 'Bonheur Royale', cursive;
}

::selection{
  background-color: #004D56;
  color:#fff;
}

.input-text{
    @apply bg-transparent focus:outline-none border border-r-0 border-l-0 border-t-0 border-greenDarK;
}


/* Hamburger Menu */
.hamburger {
    cursor: pointer;
    width: 24px;
    height: 24px;
    transition: all 0.25s;
    position: relative;
  }
  
  .hamburger-top,
  .hamburger-middle,
  .hamburger-bottom {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 2px;
    background: #000;
    transform: rotate(0);
    transition: all 0.5s;
  }
  
  .hamburger-middle {
    transform: translateY(7px);
  }
  
  .hamburger-bottom {
    transform: translateY(14px);
  }
  
  .open {
    transform: rotate(90deg);
    transform: translateY(0px);
  }
  
  .open .hamburger-top {
    transform: rotate(45deg) translateY(6px) translate(6px);
  }
  
  .open .hamburger-middle {
    display: none;
  }
  
  .open .hamburger-bottom {
    transform: rotate(-45deg) translateY(6px) translate(-6px);
  }


.certificate{
  position: relative;
  transition:all 0.4s;
  overflow: hidden;
}

.certificate .cert-img{
  transition:all 0.4s ease-in-out;
}

.certificate .overlay{
  display:block;
  position:absolute;
  visibility:hidden;
  z-index:5;
  width:100%;
  height:100%;
  top:0;
  left:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  opacity: 0.8;
  color: #ddd;
}

.certificate .overlay p{
  max-width:80%;
}

.certificate:hover .cert-img{
  transform:scale(1.2,1.2) rotate(-1deg);
}
.certificate:hover .overlay{
  visibility: visible;
}


